import Link from 'next/link'

const CTAButton = ({ value, className, path, onClick, targetBlank }: { value: string; className?: string; path: string; onClick?: () => unknown; targetBlank?: boolean }) => {
    if (onClick) {
        return (
            <div className="flex z-10">
                <button onClick={onClick} className={`${className} group py-1.5 px-4 mx-auto border-black border-t border-b text-center`}>
                    <span className="font-medium text-sm tracking-wide uppercase group-hover:font-semibold group-hover:text-primary">{value}</span>
                </button>
            </div>
        )
    }

    return (
        <div className="flex z-10">
            <Link passHref href={path} target={targetBlank ? '_blank' : '_self'} className={`${className} group py-1.5 px-4 mx-auto border-black border-t border-b text-center`}>
                <span className="font-medium text-sm tracking-wide uppercase group-hover:font-semibold group-hover:text-primary">{value}</span>
            </Link>
        </div>
    )
}

export default CTAButton
