import useTranslation from 'next-translate/useTranslation'
import CTAButton from '@components/elements/CTAButton'
import Image from 'next/image'

const DondeTodoPossible = () => {
    const { t } = useTranslation('common')

    return (
        <div className="">
            <section className="grid grid-cols-1 lg:grid-cols-2 max-w-7xl mx-auto py-6 px-4 relative xl:left-20">
                <div className="xl:mt-20 xl:pl-8">
                    <h3 className="mx-4 font-hof-clean text-gold text-7xl tracking-wide text-center lg:text-left cursor-default leading-tight">{t('oasiz:homepage.donde-todo-possible.title')}</h3>
                    <div className="my-10 w-96 xl:w-80 relative xl:bottom-28 xl:left-32 mx-auto">
                        <p className="prose text-center lg:text-left mx-auto">{t('oasiz:homepage.donde-todo-possible.content')}</p>
                        <div className="lg:flex mt-4">
                            <CTAButton className="mt-4" path={`/${t('routes.goodies')}`} value={t('goodies.title')} />
                        </div>
                    </div>
                </div>
                <div className="sm:mx-24 lg:mx-0 lg:pl-0">
                    <figure className="flex drop-shadow-sm">
                        <Image src={`${process.env.NEXT_PUBLIC_FILES_IQC_MANAGER}/files/oasiz/homepage/donde.png`} alt={t('oasiz:homepage.donde-todo-possible.title')} width="764" height="662" />
                    </figure>
                </div>
            </section>
        </div>
    )
}

export default DondeTodoPossible
