import Image from 'next/image'
import CTAButton from '@components/elements/CTAButton'
import useTranslation from 'next-translate/useTranslation'

const CentroCdp = () => {
    const { t } = useTranslation('iconic')

    return (
        <>
            <section className="hidden lg:grid grid-rows-3 grid-flow-col max-w-7xl mx-auto mt-6">
                <div className="flex row-span-3">
                    <figure className="drop-shadow-sm mx-auto mt-20 px-4">
                        <Image src={`${process.env.NEXT_PUBLIC_FILES_IQC_MANAGER}/files/oasiz/homepage/oasiz.png`} alt={t('homepage.centro-cdp.title')} width="500" height="440" />
                    </figure>
                </div>
                <div className="col-span-2">
                    <div className="flex">
                        <div className="mb-6 rotate-180 hidden xl:block">
                            <Image src="/images/purple-bracket.png" alt="" width="110" height="220" />
                        </div>
                        <h3 className="w-120 mt-8 font-hof-clean text-purple-900 text-5xl sm:text-6xl tracking-wide text-center lg:text-left cursor-default leading-tight">{t('homepage.centro-cdp.title')}</h3>
                    </div>
                </div>
                <div className="row-span-2 col-span-2 flex">
                    <div className="w-120 mt-12 px-4">
                        <p className="prose text-center lg:text-left">{t('homepage.centro-cdp.content')}</p>
                        <div className="lg:flex mt-10">
                            <CTAButton path={`/${t('common:routes.opening-access')}`} value={t('homepage.centro-cdp.link')} />
                        </div>
                    </div>
                    <div className="mt-6 hidden xl:block">
                        <Image src="/images/purple-bracket.png" alt="" width="110" height="220" />
                    </div>
                </div>
            </section>
            <section className="lg:hidden max-w-7xl mx-auto my-12 px-4">
                <h3 className="font-hof-clean text-purple-900 text-6xl tracking-wide text-center cursor-default leading-tight">{t('homepage.centro-cdp.title')}</h3>
                <div className="my-12 space-y-8 px-8">
                    <p className="prose text-center lg:text-left mx-auto">{t('homepage.centro-cdp.content')}</p>
                    <CTAButton path="https://www.compagniedephalsbourg.com/" value={t('common:see-more')} />
                </div>
                <figure className="drop-shadow-sm mx-auto text-center">
                    <Image src={`${process.env.NEXT_PUBLIC_FILES_IQC_MANAGER}/files/oasiz/homepage/oasiz.png`} alt={t('homepage.centro-cdp.title')} width="501" height="441" />
                </figure>
            </section>
        </>
    )
}

export default CentroCdp
