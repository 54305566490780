
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Head from 'next/head'
import DondeTodoPossible from '@components/homepage/DondeTodoPossible'
import HomepageLayout from '@components/homepage/HomepageLayout'
import { ReactElement, useContext } from 'react'
import { NextPageWithLayout } from './_app'
import LugarDondeDisfrutar from '@components/homepage/LugarDondeDisfrutar'
import EspacioUnico from '@components/homepage/EspacioUnico'
import CentroCdp from '@components/homepage/CentroCdp'
import { GetStaticProps } from 'next'
import fetchEntity from '@services/iqc/fetchEntity'
import RetailparkContext from '@components/context/RetailparkContext'

const PageIndex: NextPageWithLayout = () => {
    const { longName } = useContext(RetailparkContext)

    return (
        <>
            <Head>
                <title>{longName}</title>
            </Head>
            <div className="bg-i-shape bg-repeat-y bg-top">
                <DondeTodoPossible />
                <EspacioUnico />
                <LugarDondeDisfrutar />
                <CentroCdp />
            </div>
        </>
    )
}

PageIndex.getLayout = function getLayout(page: ReactElement) {
    return <HomepageLayout>{page}</HomepageLayout>
}

 const _getStaticProps: GetStaticProps = async () => {
    const retailPark: RetailPark = await fetchEntity(`retail_parks/${process.env.NEXT_PUBLIC_RETAILPARK}`)
    return { props: { retailPark } }
}

export default PageIndex


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  